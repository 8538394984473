import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { CurrencyState } from '@app/reducers/currency/currency.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';

import { getCurrencyState } from '../reducers/currency/currency.service';
import { AppState } from '../reducers/index';

@Pipe({
  name: 'money',
  pure: false, // required to update the value when the subscription is resolved
  standalone: true,
})
export class MoneyPipe implements PipeTransform, OnDestroy {
  private value;
  private lastAmount;
  private lastArgs;
  private onCurrencyChange;

  public constructor(
    private store: Store<AppState>,
    private _ref: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  public transform(
    amount: string | number,
    emptyVal: false | string = false,
    precision: number = 2,
    args?: { currencyCode?: string },
  ): string {
    const currencyCode = args?.currencyCode;

    if (!amount && emptyVal !== false) {
      return emptyVal;
    }

    //make sure the function performs when checked multiple times
    if (amount === this.lastAmount && precision === this.lastArgs) {
      return this.value;
    }

    this.lastAmount = amount;
    this.lastArgs = precision;

    // if there is a subscription to onCurrencyChange, clean it
    this._dispose();

    if (!currencyCode) {
      this.onCurrencyChange = this.store
        .select(getCurrencyState)
        .pipe(distinctUntilChanged())
        .subscribe((currencySettings: CurrencyState) => {
          this.value = this.updateValue(currencySettings.international, amount, precision);
          this._ref.markForCheck();
        });

      return this.value;
    }

    this.value = this.updateValue(currencyCode, amount, precision);
    this._ref.markForCheck();
    return this.value;
  }

  public updateValue(currency: string, amount: string | number, precision: number) {
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }

    const options = {
      style: 'currency',
      currency,
    };

    if (precision) {
      options['maximumFractionDigits'] = precision;
    }

    const numberFormat = new Intl.NumberFormat(this.translate.currentLang, options);
    return numberFormat.format(Number.isNaN(amount) ? 0 : amount);
  }

  /**
   * Clean any existing subscription to change events
   * @private
   */
  public _dispose(): void {
    if (typeof this.onCurrencyChange !== 'undefined') {
      this.onCurrencyChange.unsubscribe();
      this.onCurrencyChange = undefined;
    }
  }

  public ngOnDestroy(): void {
    this._dispose();
  }
}
